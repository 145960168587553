import { Container, StyledLink } from "@util/standard";
import React from "react";
import styled from "styled-components";
import { Maybe, SanityImage, SanityLink } from "@graphql-types";
import Image from "@components/shared/sub/image";

interface Props {
  cardImage?: Maybe<SanityImage> | undefined;
  cardTitle?: Maybe<string> | undefined;
  cardLink?: Maybe<SanityLink> | undefined;
  cardText?: Maybe<string> | undefined;
}

const ImageWrapper = styled.div`
  height: 167px;
  width: 150px;
  margin: 40px auto;
`;

const StyledTextDiv = styled.div`
  text-align: center;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: auto;
`;

const StyledP = styled.p<{ noLink?: boolean }>`
  margin-bottom: ${props => (props.noLink ? `40px` : `0px`)};
  height: 200px;
`;

const StyledH3 = styled.h3`
  height: 60px;
`;

const CardContentBlock = (props: Props) => {
  const { cardImage, cardTitle, cardText, cardLink } = props;

  return (
    <Container
      backgroundColor="white"
      width="400px"
      mobileWidth="90%"
      margin="auto"
      borderRadius={7}
      height="auto"
      mobileMargin="0px auto 30px auto"
    >
      <ImageWrapper>
        {/* <Img fixed={cardImage.asset.fixed} alt={cardImage.altText} /> */}
        {cardImage && <Image data={cardImage} />}
      </ImageWrapper>
      <StyledTextDiv>
        <StyledH3>{cardTitle}</StyledH3>

        {cardLink ? (
          <>
            <StyledP>{cardText}</StyledP>
            <Container margin="10px auto 40px auto">
              <StyledLink href={cardLink.url ?? ""} target="_blank" noMargin>
                {cardLink.linktext}
              </StyledLink>
            </Container>
          </>
        ) : (
          <StyledP noLink>{cardText}</StyledP>
        )}
      </StyledTextDiv>
    </Container>
  );
};

export default CardContentBlock;
